/* Hide scrollbar for Chrome, Safari and Opera */
.hsb::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hsb {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: "rgba(0,0,0,.7)";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#555; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}



